.floating-whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #25d366;
    color: white;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s;
    z-index: 1000;
  }
  
  .floating-whatsapp-button:hover {
    background-color: #128c7e;
    color: #128c7e;
  }
  
  .default-message {
    font-size: 14px;
    line-height: 1.5;
    background-color: #25d366;
    color: white;
    border-radius: 8px;
    padding: 10px;
    max-width: 160px;
    opacity: 1;
    transition: opacity 0.3s;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s;
    z-index: 1000;
  }

  .default-whatsapp {
    position: fixed;
    bottom: 20px;
    right: 100px;
    background-color: #25d366;
    color: white;
    border-radius: 8px;
    padding: 5px;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s;
    z-index: 1000;
  }

  .default-whatsapp:hover {
    background-color: white;
  }

  .floating-whatsapp-button.show-default-message .default-message {
    opacity: 1;
  }
  
  .floating-whatsapp-button:not(.show-default-message) .default-message {
    opacity: 0;
  }